import { useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { doc, collection, query, where, deleteDoc } from "firebase/firestore";
import { firestore, updateUser } from "../firebase/firebase";
import { useDocument, useCollection } from "react-firebase-hooks/firestore";
import Accordion from "react-bootstrap/Accordion";
import { FaCircleCheck, FaCircle } from "react-icons/fa6";
import { IoWarning } from "react-icons/io5";
import { formatNumberDeci } from "../utils/helpers";
import InfoModal from "./InfoModal";
import { updateValueInNestedObjects } from "../utils/helpers";
import ImageFromStorage from "./ImageFromStorage";
import { FaTrashAlt } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import AddPhotos from "./AddPhotos";

import ConfirmModal from "./ConfirmModal";

const BookReviewModal = ({ visible, onClose, uid, admin }) => {
  const [editableChapter, setEditableChapter] = useState(null);
  const [editableContent, setEditableContent] = useState(null);
  const [cautionModal, setCautionModal] = useState(false);
  const [deletePhotoId, setDeletePhotoId] = useState(false);
  const [confirmPrintModal, setConfirmPrintModal] = useState(false);

  const { t } = useTranslation();
  const accordionItemRef = useRef(null);
  const [userDataRaw] = useDocument(doc(firestore, "users", uid), {
    snapshotListenOptions: { includeMetadataChanges: true },
  });
  const userData = userDataRaw?.data();
  const [questionsDataRaw] = useCollection(collection(firestore, "questions"), {
    snapshotListenOptions: { includeMetadataChanges: true },
  });
  const emailRepliesQuery = query(
    collection(firestore, "email-replies"),
    userData?.email && where("sender", "==", userData?.email)
  );
  const [emailReplies] = useCollection(emailRepliesQuery, {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  const questionsData = questionsDataRaw?.docs
    ?.map((item) => ({
      context: t(`question_${item?.id}.context`),
      question: t(`question_${item?.id}.question`),
      questionAsTitle: t(`question_${item?.id}.questionAsTitle`),
      tag: item?.data()?.tag,
      id: Number(item?.id),
    }))
    ?.sort((a, b) => a?.id - b?.id);

  const bookDataForAccordion = Object.values(userData?.book_qa_data || {});

  const userPhotos = emailReplies?.docs
    ?.map((doc) => {
      const data = doc.data();
      const questionId = Number(data?.questionId);
      const id = doc?.id;
      const photos = data?.attachments?.map(
        (attachmentData) => attachmentData?.name
      );

      return {
        questionId,
        photos,
        id,
      };
    })
    ?.filter((i) => i?.photos);

  const stripClassesAndStyles = (htmlString) => {
    const tempElement = document.createElement("div");
    const htmlTagPattern = /<(?!\/?(p|b)\s?)[^>]*>/g;
    const emojiPattern =
      /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]/gu;
    tempElement.innerHTML = htmlString
      .replace(htmlTagPattern, "")
      .replace(emojiPattern, "");

    // Set empty string for class and style attributes for all elements
    const allElements = tempElement.querySelectorAll("*");
    allElements.forEach((element) => {
      element.className = "";
      element.style.cssText = "";
    });

    return tempElement.innerHTML;
  };

  const handleVerify = (id) => {
    updateUser({
      uid,
      data: {
        book_qa_data: {
          ...(userData?.book_qa_data || {}),
          [id]: {
            ...(userData?.book_qa_data?.[id] || {}),
            verified: true,
          },
        },
      },
    });
    setEditableContent(null);
    setEditableChapter(null);
  };

  const handleSave = (id) => {
    updateUser({
      uid,
      data: {
        book_qa_data: {
          ...(userData?.book_qa_data || {}),
          [id]: {
            ...(userData?.book_qa_data?.[id] || {}),
            text:
              editableContent !== null
                ? stripClassesAndStyles(editableContent)
                : userData?.book_qa_data?.[id]?.text,
          },
        },
      },
    });
    setEditableContent(null);
    setEditableChapter(null);
  };

  const handleSkipQuestion = (id) => {
    updateUser({
      uid,
      data: {
        book_qa_data: {
          ...(userData?.book_qa_data || {}),
          [id]: {
            ...(userData?.book_qa_data?.[id] || {}),
            text: "skip",
          },
        },
      },
    });
  };

  const handleVerifyAll = async () => {
    const verifiedBookQAData = updateValueInNestedObjects(
      userData?.book_qa_data,
      "verified",
      true
    );
    try {
      updateUser({
        uid,
        data: {
          book_qa_data: verifiedBookQAData,
        },
      });
    } catch (error) {
      console.error("Error updating document: ", error);
    } finally {
      setEditableContent(null);
      setEditableChapter(null);
    }
  };

  const handleSendToPrint = async () => {
    try {
      const data = {
        ready_for_print_book: Math.round(Date.now() / 1000),
      };
      await updateUser({ uid, data });
    } catch (error) {
      console.error("Error updating document: ", error);
    } finally {
      onClose();
    }
  };

  const handleDeletePhoto = async () => {
    try {
      const deleteDocRef = doc(firestore, "email-replies", deletePhotoId);

      await deleteDoc(deleteDocRef);
    } catch (error) {
      console.error("Error updating document: ", error);
    } finally {
      setDeletePhotoId(false);
    }
  };

  const renderImages = (questionId) => {
    return userPhotos
      ?.filter((i) => i?.questionId === questionId)
      ?.map((ii) => {
        if (ii?.photos?.length > 0) {
          return ii?.photos?.map(
            (photoUrl) =>
              photoUrl && (
                <div
                  key={photoUrl}
                  style={{
                    marginRight: 16,
                    marginBottom: 16,
                    position: "relative",
                    borderStyle: "solid",
                    borderColor: "grey",
                    borderWidth: 1,
                    padding: 4,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <FaTrashAlt
                    color="black"
                    size={16}
                    onClick={() => setDeletePhotoId(ii?.id)}
                    style={{ marginBottom: 4, cursor: "pointer" }}
                  />

                  <ImageFromStorage
                    link={photoUrl}
                    height={90}
                    objectFit="contain"
                  />
                </div>
              )
          );
        }
        return null;
      });
  };

  return (
    <>
      <Modal size="lg" fullscreen show={visible} onHide={onClose}>
        <Modal.Header closeButton>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <p style={{ fontSize: 40, fontWeight: 700, marginBottom: 4 }}>
              {t("dashboardScreen.reviewBook")}
            </p>
            <p>{t("dashboardScreen.checkAndVerify")}</p>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Accordion>
            {bookDataForAccordion?.map((item, idx) => (
              <Accordion.Item eventKey={idx}>
                <Accordion.Header ref={accordionItemRef}>
                  {item?.verified ? (
                    <FaCircleCheck color="#60A680" />
                  ) : (
                    <FaCircle color="silver" />
                  )}
                  <span style={{ marginLeft: 8 }}>
                    Chapter {formatNumberDeci(idx + 1)}.{" "}
                    {questionsData?.[idx]?.questionAsTitle}
                  </span>
                </Accordion.Header>
                <Accordion.Body>
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        item?.text === "skip"
                          ? t("dashboardScreen.youDidNotAnswerThisQuestion")
                          : item?.text,
                    }}
                    contentEditable={editableChapter === idx}
                    onInput={(event) => {
                      setEditableContent(event.target.innerHTML);
                    }}
                    className={editableChapter === idx && "content-editable"}
                    style={{ marginBottom: 20 }}
                  ></div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {renderImages(item?.questionId)}

                    <div style={{ marginBottom: 20 }}>
                      <AddPhotos questionId={idx + 1} uid={uid} />
                    </div>
                  </div>
                  {(!item?.verified || admin) && (
                    <>
                      {admin && (
                        <Button
                          onClick={() => {
                            handleSkipQuestion(idx + 1);
                          }}
                          className="button-update"
                          variant="outline-danger"
                          style={{
                            marginRight: 8,
                          }}
                        >
                          Skip
                        </Button>
                      )}
                      {editableChapter === idx ? (
                        <Button
                          onClick={() => {
                            handleSave(idx + 1);
                          }}
                          className="button-update"
                          variant="dark"
                          style={{
                            marginRight: 8,
                          }}
                        >
                          {t("common.save")}
                        </Button>
                      ) : (
                        <Button
                          onClick={() => {
                            handleVerify(idx + 1);
                            accordionItemRef?.current
                              ?.querySelector("button")
                              ?.click();
                          }}
                          className="button-update"
                          style={{
                            backgroundColor: "#60A680",
                            borderColor: "#60A680",
                            marginRight: 8,
                          }}
                        >
                          {t("common.verify")}
                        </Button>
                      )}
                      <Button
                        onClick={() => {
                          editableChapter === null &&
                            !admin &&
                            setCautionModal(true);
                          setEditableChapter(
                            editableChapter === idx ? null : idx
                          );
                        }}
                        className="button-update"
                        variant="outline-dark"
                      >
                        {editableChapter === idx
                          ? t("common.cancel")
                          : t("common.edit")}
                      </Button>
                    </>
                  )}
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="button-update"
            variant="outline-dark"
            onClick={onClose}
          >
            {t("common.close")}
          </Button>
          <Button
            className="button-update"
            variant="dark"
            onClick={handleVerifyAll}
          >
            {t("dashboardScreen.verifyAll")}
          </Button>
          <Button
            disabled={!userData?.book_qa_data?.[50]?.verified}
            className="button-update"
            style={{ backgroundColor: "#60A680", borderColor: "#60A680" }}
            onClick={() => setConfirmPrintModal(true)}
          >
            {t("dashboardScreen.sendToPrint")}
          </Button>
        </Modal.Footer>
      </Modal>
      <InfoModal
        visible={cautionModal}
        onHide={() => setCautionModal(false)}
        title={t("dashboardScreen.caution")}
        text={t("dashboardScreen.editThisTextAtYourRisk")}
        icon={<IoWarning size={26} style={{ marginRight: 4 }} color="orange" />}
      />
      <ConfirmModal
        visible={deletePhotoId}
        onHide={() => setDeletePhotoId(false)}
        title={t("dashboardScreen.areYouSure")}
        text={t("dashboardScreen.youWillDeleteThisPhoto")}
        icon={<IoWarning size={26} style={{ marginRight: 4 }} color="orange" />}
        onConfirm={handleDeletePhoto}
      />
      <ConfirmModal
        visible={confirmPrintModal}
        onHide={() => setConfirmPrintModal(false)}
        title={t("dashboardScreen.confirmBookContent")}
        text={t("dashboardScreen.youAreResonsibleForBookContent")}
        onConfirm={handleSendToPrint}
      />
    </>
  );
};

export default BookReviewModal;
