import { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { addUser } from "../firebase/firebase";

const CustomerSupport = () => {
  const [formData, setFormData] = useState({});
  const [userData, setUserData] = useState({});
  const [successOnEmailSent, setSuccessOnEmailSent] = useState(false);
  const [successOnUserCreation, setSuccessOnUserCreation] = useState(false);

  const handleEmailInputChange = (e) => {
    setSuccessOnEmailSent(false);

    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleUserInputChange = (e) => {
    setSuccessOnUserCreation(false);

    const { name, value } = e.target;
    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const handleSendEmail = async (e) => {
    e.preventDefault();

    try {
      const url =
        "https://memowrite.app.n8n.cloud/webhook/fb5f4c74-bf7b-4397-8f46-c4186f6b542d";
      const dataToSend = {
        email: formData?.email,
        name: formData?.name || "Writer",
        questionId: formData?.questionId,
        minutesToWait: 0,
        language: formData?.language || "en",
      };

      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(dataToSend),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      if (data) {
        setSuccessOnEmailSent(true);
      }
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  const handleCreateUser = async (e) => {
    e.preventDefault();

    const uid = userData?.uid;
    const data = {
      name: userData?.name,
      email: userData?.email,
      access: true,
      platform: "email",
      generate_book: Math.round(Date.now() / 1000),
    };

    try {
      await addUser({ uid, data });
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    } finally {
      setSuccessOnUserCreation(true);
    }
  };

  return (
    <>
      <h4 className="mb-2">Customer support</h4>
      <Row>
        <Col sm={6} className="mb-4">
          <h6 className="mb-2">Send email with question</h6>
          <Form onSubmit={handleSendEmail} style={{ textAlign: "left" }}>
            <Form.Group className="mb-2" controlId="formBasicEmail">
              <Form.Control
                value={formData.email}
                onChange={handleEmailInputChange}
                placeholder="Customer email"
                name="email"
                type="email"
              />
            </Form.Group>
            <Form.Group className="mb-2" controlId="formBasicPassword">
              <Form.Control
                value={formData.name}
                onChange={handleEmailInputChange}
                placeholder="Customer name"
                name="name"
              />
            </Form.Group>
            <Form.Group className="mb-2" controlId="formBasicPassword">
              <Form.Control
                value={formData.questionId}
                onChange={handleEmailInputChange}
                placeholder="Question number"
                name="questionId"
              />
            </Form.Group>
            <Form.Group className="mb-2" controlId="formBasicPassword">
              <Form.Control
                value={formData.language}
                onChange={handleEmailInputChange}
                placeholder="Language (en or es)"
                name="language"
              />
            </Form.Group>
            <Button variant="dark" type="submit" className="button-update">
              Send
            </Button>
            {successOnEmailSent && (
              <span
                style={{ fontWeight: 700, color: "#60A680", marginLeft: 16 }}
              >
                Email sent
              </span>
            )}
          </Form>
        </Col>
        <Col sm={6} className="mb-4">
          <h6 className="mb-2">Create user entry (from existing account)</h6>
          <Form onSubmit={handleCreateUser} style={{ textAlign: "left" }}>
            <Form.Group className="mb-2" controlId="formBasicUid">
              <Form.Control
                value={userData.uid}
                onChange={handleUserInputChange}
                placeholder="uid"
                name="uid"
              />
            </Form.Group>
            <Form.Group className="mb-2" controlId="formBasicEmail">
              <Form.Control
                value={userData.email}
                onChange={handleUserInputChange}
                placeholder="Email"
                name="email"
                type="email"
              />
            </Form.Group>
            <Form.Group className="mb-2" controlId="formBasicName">
              <Form.Control
                value={userData.name}
                onChange={handleUserInputChange}
                placeholder="Full name"
                name="name"
              />
            </Form.Group>
            <Button variant="dark" type="submit" className="button-update">
              Create user entry
            </Button>
            {successOnUserCreation && (
              <span
                style={{ fontWeight: 700, color: "#60A680", marginLeft: 16 }}
              >
                User entry created
              </span>
            )}
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default CustomerSupport;
