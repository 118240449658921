import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { doc, deleteDoc } from "firebase/firestore";
import { firestore } from "../firebase/firebase";
import { useTranslation } from "react-i18next";

const DeleteModal = ({ deleteId, onHide }) => {
  const { t } = useTranslation();

  const handleDelete = async () => {
    try {
      const deleteDocRef = doc(firestore, "email-replies", deleteId);

      await deleteDoc(deleteDocRef);
    } catch (error) {
      console.error("Error updating document: ", error);
    } finally {
      onHide();
    }
  };

  return (
    <Modal show={deleteId} onHide={onHide} centered>
      <Modal.Header closeButton style={{ border: "none" }} />
      <Modal.Body>
        <p style={{ fontSize: 24, fontWeight: 500, marginBottom: 10 }}>
          {t("dashboardScreen.areYouSure")}
        </p>
        <p>{t("dashboardScreen.answerDeletedPermanently")}</p>
      </Modal.Body>
      <Modal.Footer style={{ border: "none" }}>
        <Button
          variant="danger"
          onClick={handleDelete}
          style={{
            borderRadius: 20,
            paddingTop: 8,
            paddingBottom: 8,
            paddingLeft: 32,
            paddingRight: 32,
            fontWeight: 600,
          }}
        >
          {t("common.delete")}
        </Button>
        <Button
          variant="outline-dark"
          onClick={onHide}
          style={{
            borderRadius: 20,
            paddingTop: 8,
            paddingBottom: 8,
            paddingLeft: 32,
            paddingRight: 32,
            fontWeight: 600,
          }}
        >
          {t("common.cancel")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteModal;
