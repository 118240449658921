import { useState } from "react";
import {
  introductionPrompt,
  chapter1Prompt,
  chapter2part1Prompt,
  chapter2part2Prompt,
  chapter2part3Prompt,
  chapter2part4Prompt,
  chapter3part1Prompt,
  chapter3part2Prompt,
  chapter3part3Prompt,
  chapter4part1Prompt,
  chapter4part2Prompt,
  chapter4part3Prompt,
  chapter5part1Prompt,
  chapter5part2Prompt,
  chapter6part1Prompt,
  chapter6part2Prompt,
  conclusionPrompt,
} from "./prompts";
import { groupObjectsByProperty } from "./helpers";

const fetchChatGPT = (prompt, answers, language, cb) => {
  const systemMessage = {
    role: "system",
    content:
      language === "es"
        ? "Eres un escritor galardonado de memorias. Tu estilo de escritura es sencillo, relatable e íntimo. Proporciona una respuesta en código HTML. No utilices las etiquetas <html/>, <body/>, <h1/>. Formatea los párrafos con las etiquetas <p/>. No escribas titulares. Eliminar símbolos emoji."
        : "You are an award winning memoir writer. Your writing style is simple, relatable, intimate. Provide an answer in html code. Do not use <html/> <body/> <h1/> tags. Format paragraphs with <p/> tags. Paragraph text alignment: justify. Do not write headlines. Remove emoji symbols. Remove text: Sent from Yahoo Mail.",
  };
  const dataToSend = {
    model: "gpt-3.5-turbo",
    messages: [systemMessage, { role: "user", content: prompt(answers) }],
    temperature: 0.7,
  };

  return fetch("https://api.openai.com/v1/chat/completions", {
    method: "POST",
    headers: {
      Authorization: "Bearer " + process.env.REACT_APP_OPENAI_SECRET,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(dataToSend),
  })
    .then((data) => data.json())
    .then((finalData) => {
      cb(finalData.choices[0].message.content);
      console.log(finalData.choices[0].message.content);
    });
};

const fetchChatGPTSimple = (prompt, model = "gpt-3.5-turbo", language) => {
  const systemMessage = {
    role: "system",
    content:
      language === "es"
        ? "Eres un escritor de memorias galardonado. Tu estilo de escritura es simple, relatable e íntimo. Proporciona una respuesta en código HTML. No uses etiquetas <html/>, <body/>, <h1/>. Formatea los párrafos con etiquetas <p/>. No escribas titulares. Eliminar símbolos emoji."
        : "You are an award winning memoir writer. Your writing style is simple, relatable, intimate. Provide an answer in html code. Do not use <html/> <body/> <h1/> tags. Format paragraphs with <p/> tags. Do not write headlines. Remove emoji symbols. Remove text: Sent from Yahoo Mail.",
  };
  const dataToSend = {
    // model: "gpt-4",
    model,
    messages: [systemMessage, { role: "user", content: prompt }],
    temperature: 0.3,
  };

  return fetch("https://api.openai.com/v1/chat/completions", {
    method: "POST",
    headers: {
      Authorization: "Bearer " + process.env.REACT_APP_OPENAI_SECRET,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(dataToSend),
  })
    .then((data) => data.json())
    .then((finalData) => finalData.choices[0].message.content);
};

export function useGenerateBookStory(userAnswers, onEnd) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState({});

  const simplifiedAnswers = userAnswers
    ?.map((a) => ({
      questionId: Number(a?.questionId),
      answer: a?.["stripped-text"],
      photos: a?.photos,
    }))
    .sort((a, b) => a?.questionId - b?.questionId);

  const groupedByQuestionId =
    groupObjectsByProperty(simplifiedAnswers, "questionId") || {};

  const textAnswers = Object.values(groupedByQuestionId)?.map((answers) => {
    let singleAnswer = "";
    answers?.forEach((a) => {
      if (a?.answer && a?.answer !== null) {
        singleAnswer += a?.answer;
      }
    });
    return singleAnswer;
  });

  const generate = async () => {
    try {
      setLoading(true);
      const book = {};

      await fetchChatGPT(
        introductionPrompt,
        textAnswers,
        (value) => (book.introduction = value)
      );
      await fetchChatGPT(
        chapter1Prompt,
        textAnswers,
        (value) => (book.chapter1 = value)
      );
      await fetchChatGPT(
        chapter2part1Prompt,
        textAnswers,
        (value) => (book.chapter2part1 = value)
      );
      await fetchChatGPT(
        chapter2part2Prompt,
        textAnswers,
        (value) => (book.chapter2part2 = value)
      );
      await fetchChatGPT(
        chapter2part3Prompt,
        textAnswers,
        (value) => (book.chapter2part3 = value)
      );
      await fetchChatGPT(
        chapter2part4Prompt,
        textAnswers,
        (value) => (book.chapter2part4 = value)
      );
      await fetchChatGPT(
        chapter3part1Prompt,
        textAnswers,
        (value) => (book.chapter3part1 = value)
      );
      await fetchChatGPT(
        chapter3part2Prompt,
        textAnswers,
        (value) => (book.chapter3part2 = value)
      );
      await fetchChatGPT(
        chapter3part3Prompt,
        textAnswers,
        (value) => (book.chapter3part3 = value)
      );
      await fetchChatGPT(
        chapter4part1Prompt,
        textAnswers,
        (value) => (book.chapter4part1 = value)
      );
      await fetchChatGPT(
        chapter4part2Prompt,
        textAnswers,
        (value) => (book.chapter4part2 = value)
      );
      await fetchChatGPT(
        chapter4part3Prompt,
        textAnswers,
        (value) => (book.chapter4part3v = value)
      );
      await fetchChatGPT(
        chapter5part1Prompt,
        textAnswers,
        (value) => (book.chapter5part1 = value)
      );
      await fetchChatGPT(
        chapter5part2Prompt,
        textAnswers,
        (value) => (book.chapter5part2 = value)
      );
      await fetchChatGPT(
        chapter6part1Prompt,
        textAnswers,
        (value) => (book.chapter6part1 = value)
      );
      await fetchChatGPT(
        chapter6part2Prompt,
        textAnswers,
        (value) => (book.chapter6part2 = value)
      );
      await fetchChatGPT(
        conclusionPrompt,
        textAnswers,
        (value) => (book.conclusion = value)
      );

      setData(book);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
      onEnd();
    }
  };

  return { generate, loading, error, data };
}

export function useGenerateBookQA(
  userQuestions,
  onEnd,
  minGenNum = 1,
  maxGenNum = 50,
  book_qa_data,
  model,
  language
) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState({});

  const modifiedUserQuestions = userQuestions
    ?.filter(
      (i) =>
        minGenNum <= Number(i?.questionId) && Number(i?.questionId) <= maxGenNum
    )
    ?.map((item) => {
      let singleTextAnswer = "";

      item?.answers?.forEach((a) => {
        if (a?.["stripped-text"] && a?.["stripped-text"] !== null) {
          singleTextAnswer += a?.["stripped-text"];
        }
      });

      return {
        questionId: item?.questionId,
        question: item?.question,
        questionAsTitle: item?.questionAsTitle,
        answer: singleTextAnswer,
        photos: item?.photos,
      };
    });

  const generate = async () => {
    try {
      setLoading(true);
      const texts = (answer) => ({
        en: {
          48: `Rewrite TEXT. Firstly, proofread text by fixing grammar and syntax. Secondly, split this text into paragraphs and format paragraphs with html <p> tags. Thirdly, highlight the book title from the text with <b> html tag. Return only TEXT. TEXT: "${answer}"`,
          other: `Rewrite TEXT. Firstly, proofread text by fixing grammar and syntax. Secondly, split this text into paragraphs and format paragraphs with html <p> tags. Return only TEXT. TEXT: "${answer}"`,
        },
        es: {
          48: `Reescribe el TEXTO. En primer lugar, corrige el texto revisando la gramática y la sintaxis. En segundo lugar, divide este texto en párrafos y formatea los párrafos con etiquetas HTML <p>. En tercer lugar, resalta el título del libro en el texto con la etiqueta HTML <b>. Devuelve solo el TEXTO. TEXTO: "${answer}"`,
          other: `Reescribe el TEXTO. En primer lugar, corrige el texto revisando la gramática y la sintaxis. En segundo lugar, divide este texto en párrafos y formatea los párrafos con etiquetas HTML <p>. Devuelve solo el TEXTO. TEXTO: "${answer}"`,
        },
      });

      const promises = modifiedUserQuestions.map(async (item) => {
        if (item?.answer) {
          const prompt =
            Number(item?.questionId) === 48
              ? texts(item?.answer)?.[language]?.[48]
              : texts(item?.answer)?.[language]?.other;
          return await fetchChatGPTSimple(prompt, model, language);
        }
      });
      const results = await Promise.all(promises);
      const merged = modifiedUserQuestions?.map((item, idx) => ({
        ...item,
        answer: results?.[idx],
      }));

      setData(merged);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
      onEnd();
    }
  };

  const getData = () => {
    const merged = modifiedUserQuestions?.map((item, idx) => ({
      ...item,
      answer: book_qa_data?.[Number(item?.questionId)]?.text,
    }));
    setData(merged);
  };

  return { generate, getData, loading, error, data };
}
