import { collection, where, query, doc } from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import { auth } from "../firebase/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { firestore } from "../firebase/firebase";
import Progress from "../components/Progress";
import MainActivity from "../components/MainActivity";
import AllAnswers from "../components/AllAnswers";
import { getStructuredUserQuestionsWithAnswers } from "../utils/dataHelpers";
import { useDocument } from "react-firebase-hooks/firestore";

function DashboardScreen() {
  const [user] = useAuthState(auth);
  const emailRepliesQuery = query(
    collection(firestore, "email-replies"),
    where("sender", "==", user?.email)
  );
  const [emailReplies, emailRepliesLoading, emailRepliesError] = useCollection(
    emailRepliesQuery,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );
  const [questions, questionsLoading, questionsError] = useCollection(
    collection(firestore, "questions"),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );
  const [userDataRaw] = useDocument(doc(firestore, "users", user?.uid), {
    snapshotListenOptions: { includeMetadataChanges: true },
  });
  const userData = userDataRaw?.data();

  const structuredUserQuestionsWithAnswers =
    getStructuredUserQuestionsWithAnswers({
      questions,
      answers: emailReplies,
      userEmail: user?.email,
      bookQaPhotos: userData?.book_qa_photos,
    });

  return (
    <div className="mt-4">
      <Progress userQuestions={structuredUserQuestionsWithAnswers} />
      <MainActivity userQuestions={structuredUserQuestionsWithAnswers} />
      <div className="divider"></div>
      <AllAnswers
        isLoading={emailRepliesLoading || questionsLoading}
        answers={structuredUserQuestionsWithAnswers}
      />
    </div>
  );
}

export default DashboardScreen;
