import pdfMake from "pdfmake/build/pdfmake";
import htmlToPdfmake from "html-to-pdfmake";
import pdfFonts from "../assets/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.fonts = {
  Avenir: {
    normal: "Avenir-Book.ttf",
    medium: "Avenir-Medium.ttf",
    bold: "Avenir-Heavy.ttf",
  },
  Sentient: {
    normal: "Sentient-Regular.ttf",
    italics: "Sentient-Italic.ttf",
    medium: "Sentient-Medium.ttf",
    mediumitalics: "Sentient-MediumItalic.ttf",
    bold: "Sentient-Bold.ttf",
    bolditalics: "Sentient-BoldItalic.ttf",
  },
};

export function createPDF(ref, author, { title, page }) {
  const html = htmlToPdfmake(ref.outerHTML, {
    imagesByReference: true,
    removeExtraBlanks: true,
    defaultStyles: {
      p: {
        font: "Avenir",
        lineHeight: 1.2,
        margin: [0, 0, 0, 10],
      },
      h1: {
        font: "Sentient",
        bold: true,
        italics: true,
        marginTop: 60,
        marginBottom: 250,
        alignment: "center",
      },
      h2: {
        font: "Sentient",
      },
      h3: {
        font: "Sentient",
      },
      h4: {
        font: "Sentient",
        margin: [0, 75, 0, 55],
        bold: true,
        italics: true,
      },
      h5: {
        font: "Sentient",
      },
      h6: {
        font: "Sentient",
      },
    },
  });
  const pdfSettings = {
    content: html.content,
    info: {
      title,
      author,
    },
    images: html.images,
    pageSize: "A5",
    styles: {
      "html-img": {
        alignment: "center",
        marginTop: 70,
      },
      headline: {
        alignment: "center",
        font: "Sentient",
        bold: true,
        italics: true,
      },
      "author-text": {
        alignment: "center",
        characterSpacing: 1,
        marginBottom: 5,
      },
      "last-page-text": {
        alignment: "center",
        fontSize: 10,
      },
      "last-page-logo": {
        margin: [0, 380, 0, 14],
      },
    },
    defaultStyle: {
      font: "Avenir",
    },
    pageBreakBefore: function (currentNode) {
      return (
        currentNode.style &&
        currentNode.style.indexOf("pdf-pagebreak-before") > -1
      );
    },
    pageMargins: [50, 50, 50, 70],
    footer: function (currentPage, pageCount) {
      if (currentPage === 1 || currentPage === 2 || currentPage === pageCount) {
        return;
      }
      return currentPage % 2 === 0
        ? {
            columns: [
              {
                width: "auto",
                text: `${currentPage.toString()}`,
                bold: true,
                fontSize: 8,
                marginTop: 17,
                marginLeft: 50,
              },
              {
                width: "auto",
                text: "|",
                fontSize: 20,
                margin: [6, 10, 6, 0],
              },
              {
                width: "auto",
                text: title.toUpperCase(),
                fontSize: 8,
                marginTop: 17,
              },
            ],
          }
        : {
            columns: [
              {
                width: "*",
                text: "",
              },
              {
                width: "auto",
                text: author?.toUpperCase(),
                fontSize: 8,
                marginTop: 17,
              },
              {
                width: "auto",
                text: "|",
                fontSize: 20,
                margin: [6, 10, 6, 0],
              },
              {
                width: "auto",
                text: `${currentPage.toString()}`,
                bold: true,
                fontSize: 8,
                marginTop: 17,
                marginRight: 50,
              },
            ],
          };
    },
  };
  pdfMake.createPdf(pdfSettings).download();
}
