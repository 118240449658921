import { auth, updateUser, storage } from "../firebase/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { ref, uploadBytes } from "firebase/storage";
import Form from "react-bootstrap/Form";

const AddPhoto = ({ disabled }) => {
  const [user] = useAuthState(auth);

  const generateUniqueId = () => {
    const timestamp = Date.now();
    const randomNum = Math.floor(Math.random() * 1000);
    return (timestamp + randomNum).toString(36);
  };

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];

    try {
      // Upload photo
      const photoPath = `${user?.email?.replace(
        /[^a-zA-Z0-9]+/g,
        "_"
      )}_${generateUniqueId()}_${selectedFile?.name}`;
      const photoRef = ref(storage, photoPath);
      const uploadedFile = await uploadBytes(photoRef, selectedFile);

      // Update user data
      await updateUser({
        uid: user?.uid,
        data: { cover_photo: uploadedFile?.metadata?.fullPath },
      });
    } catch (error) {
      console.error("Error adding photo: ", error);
    }
  };

  return (
    <Form>
      <Form.Group controlId="formFile" className="mb-3">
        <Form.Control
          type="file"
          accept=".png, .jpg, .jpeg"
          onChange={handleFileChange}
          disabled={disabled}
        />
      </Form.Group>
    </Form>
  );
};

export default AddPhoto;
