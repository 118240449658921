import { useState, useMemo } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { updateUser, auth } from "../firebase/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import countryList from "react-select-country-list";

const AddShippingAddress = () => {
  const [validated, setValidated] = useState(false);
  const [country, setCountry] = useState({
    label: "United States",
    value: "US",
  });

  const [user] = useAuthState(auth);
  const { t } = useTranslation();

  const countries = useMemo(() => countryList().getData(), []);

  const onCountryChange = (value) => {
    setCountry(value);
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      const data = {
        shipping_address: {
          first_name: event.target.firstName.value,
          last_name: event.target.lastName.value,
          country_code: country?.value,
          phone: event.target.phone.value,
          city: event.target.city.value,
          street_address: event.target.streetAddress.value,
          apartment: event.target?.apartment?.value,
          state_code: event.target.state.value,
          zip_code: event.target.zipCode.value,
        },
      };

      updateUser({
        uid: user?.uid,
        data,
      });
    }

    setValidated(true);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h2>{t("dashboardScreen.whatsYourShippingAddress")}</h2>
        <p style={{ marginBottom: 32 }}>
          {t("dashboardScreen.yourBookHalfwayThere")}
        </p>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Row>
            <Form.Group as={Col} md="6" className="mb-3" controlId="firstName">
              <Form.Label>{t("dashboardScreen.firstName")}</Form.Label>
              <Form.Control required type="text" />
            </Form.Group>
            <Form.Group as={Col} md="6" className="mb-3" controlId="lastName">
              <Form.Label>{t("dashboardScreen.lastName")}</Form.Label>
              <Form.Control required type="text" />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} md="6" className="mb-3" controlId="country">
              <Form.Label>{t("common.country")}</Form.Label>
              <Select
                options={countries}
                value={country}
                onChange={onCountryChange}
              />
            </Form.Group>
            <Form.Group as={Col} md="6" className="mb-3" controlId="phone">
              <Form.Label>{t("dashboardScreen.phoneNumber")}</Form.Label>
              <Form.Control required type="text" />
              <Form.Control.Feedback type="invalid">
                {t("dashboardScreen.provideNumber")}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group
              as={Col}
              md="6"
              className="mb-3"
              controlId="streetAddress"
            >
              <Form.Label>{t("dashboardScreen.streetAddress")}</Form.Label>
              <Form.Control required type="text" maxLength={30} />
              <Form.Control.Feedback type="invalid">
                {t("dashboardScreen.provideStreetAddress")}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6" className="mb-3" controlId="apartment">
              <Form.Label>{t("dashboardScreen.aptOptional")}</Form.Label>
              <Form.Control type="text" />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} md="6" className="mb-3" controlId="city">
              <Form.Label>{t("common.city")}</Form.Label>
              <Form.Control type="text" required />
              <Form.Control.Feedback type="invalid">
                {t("dashboardScreen.provideCity")}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="3" className="mb-3" controlId="state">
              <Form.Label>{t("common.state")}</Form.Label>
              <Form.Control type="text" placeholder="e.g. NY" maxLength={2} />
              <Form.Control.Feedback type="invalid">
                {t("dashboardScreen.provideState")}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="3" className="mb-3" controlId="zipCode">
              <Form.Label>{t("dashboardScreen.zipCode")}</Form.Label>
              <Form.Control type="text" required />
              <Form.Control.Feedback type="invalid">
                {t("dashboardScreen.provideZipCode")}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Col className="d-flex justify-content-center mt-4">
            <Button variant="dark" className="button-update" type="submit">
              {t("common.submit")}
            </Button>
          </Col>
        </Form>
      </div>
    </>
  );
};

export default AddShippingAddress;
