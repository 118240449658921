import { useState } from "react";
import { useUpdatePassword } from "react-firebase-hooks/auth";
import { reauthenticateWithCredential, EmailAuthProvider } from "firebase/auth";
import { auth } from "../firebase/firebase";
import { useTranslation } from "react-i18next";

import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";

const ChangePassModal = ({ visible, onHide, user }) => {
  const [currentPass, setCurrentPass] = useState(null);
  const [newPass, setNewPass] = useState(null);
  const [newPassRetype, setNewPassRetype] = useState(null);
  const [inputError, setInputError] = useState(null);

  const { t } = useTranslation();
  const [updatePassword, updating, error] = useUpdatePassword(auth);

  const handleCurrentPassChange = (e) => {
    const { value } = e.target;
    setInputError(null);
    setCurrentPass(value);
  };

  const handleNewPassChange = (e) => {
    const { value } = e.target;
    setInputError(null);
    setNewPass(value);
  };

  const handleNewPassRetypePassChange = (e) => {
    const { value } = e.target;
    setInputError(null);
    setNewPassRetype(value);
  };

  const handleSave = async () => {
    try {
      if (!currentPass || !newPass || !newPassRetype) {
        setInputError(t("profileScreen.fillAllFields"));
        return;
      }
      if (newPass?.length < 7 || currentPass?.length < 7) {
        setInputError(t("profileScreen.passwordAtLeast8"));
        return;
      }
      if (newPass !== newPassRetype) {
        setInputError(t("profileScreen.passwordsDoNotMatch"));
        return;
      }

      const credential = EmailAuthProvider.credential(user?.email, currentPass);
      await reauthenticateWithCredential(user, credential);
      const success = await updatePassword(newPass);

      if (success) {
        onHide();
      }
    } catch (error) {
      setInputError(t("profileScreen.somethingWentWrong"));
      console.log(error);
    }
  };

  return (
    <Modal
      show={visible}
      onHide={onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton style={{ border: "none" }} onHide={onHide}>
        <Modal.Title>{t("profileScreen.changePassword")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InputGroup className="mb-3">
          <InputGroup.Text id="inputGroup-sizing-default">
            {t("profileScreen.currentPassword")}
          </InputGroup.Text>
          <Form.Control
            type="password"
            aria-label="Default"
            aria-describedby="inputGroup-sizing-default"
            value={currentPass}
            onChange={handleCurrentPassChange}
          />
        </InputGroup>
        <InputGroup className="mb-3">
          <InputGroup.Text id="inputGroup-sizing-default">
            {t("profileScreen.newPassword")}
          </InputGroup.Text>
          <Form.Control
            type="password"
            aria-label="Default"
            aria-describedby="inputGroup-sizing-default"
            value={newPass}
            onChange={handleNewPassChange}
          />
        </InputGroup>
        <InputGroup className="mb-4">
          <InputGroup.Text id="inputGroup-sizing-default">
            {t("profileScreen.retypeNewPassword")}
          </InputGroup.Text>
          <Form.Control
            type="password"
            aria-label="Default"
            aria-describedby="inputGroup-sizing-default"
            value={newPassRetype}
            onChange={handleNewPassRetypePassChange}
          />
        </InputGroup>
        <span className="text-danger">{inputError}</span>
      </Modal.Body>
      <Modal.Footer style={{ border: "none" }}>
        <Button variant="dark" onClick={handleSave}>
          {t("common.save")}
        </Button>
        <Button variant="outline-dark" onClick={onHide}>
          {t("common.cancel")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChangePassModal;
