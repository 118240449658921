import { useState, useMemo } from "react";
import Modal from "react-bootstrap/Modal";
import { COVERS } from "../utils/data";
import ImageFromStorage from "./ImageFromStorage";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Select from "react-select";
import countryList from "react-select-country-list";
import { updateUser } from "../firebase/firebase";
import { useTranslation } from "react-i18next";

const ClientInfoModal = ({ visible, onClose, onRefresh, clientInfo }) => {
  const [country, setCountry] = useState({
    label: "United States",
    value: "US",
  });

  const { t } = useTranslation();

  const countries = useMemo(() => countryList().getData(), []);

  const onCountryChange = (value) => {
    setCountry(value);
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      const data = {
        shipping_address: {
          first_name: event.target.firstName.value,
          last_name: event.target.lastName.value,
          country_code: country?.value,
          phone: event.target.phone.value,
          city: event.target.city.value,
          street_address: event.target.streetAddress.value,
          apartment: event.target?.apartment?.value,
          state_code: event.target.state.value,
          zip_code: event.target.zipCode.value,
        },
      };

      updateUser({
        uid: clientInfo?.uid,
        data,
      });
    }

    onRefresh();
    onClose();
  };

  return (
    <Modal show={visible} onHide={onClose} size="lg" centered>
      <Modal.Header closeButton style={{ border: "none" }}>
        <h3 style={{ marginTop: 16 }}>Client details</h3>
      </Modal.Header>
      <Modal.Body>
        <h5>Book info</h5>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: 12,
          }}
        >
          <div>
            <p>Cover</p>
            {clientInfo?.cover && (
              <img
                alt="cover"
                src={COVERS?.[clientInfo?.cover - 1]}
                style={{ height: 140 }}
              />
            )}
          </div>
          <div style={{ marginLeft: 40 }}>
            <p>Cover photo</p>
            {clientInfo?.cover_photo && (
              <ImageFromStorage link={clientInfo?.cover_photo} height={140} />
            )}
          </div>
        </div>
        <hr />
        <h5 style={{ marginTop: 32, marginBottom: 16 }}>Shipping address</h5>
        <Form noValidate onSubmit={handleSubmit}>
          <Row>
            <Form.Group as={Col} md="6" className="mb-3" controlId="firstName">
              <Form.Label>{t("dashboardScreen.firstName")}</Form.Label>
              <Form.Control
                defaultValue={clientInfo?.shipping_address?.first_name}
                type="text"
              />
            </Form.Group>
            <Form.Group as={Col} md="6" className="mb-3" controlId="lastName">
              <Form.Label>{t("dashboardScreen.lastName")}</Form.Label>
              <Form.Control
                defaultValue={clientInfo?.shipping_address?.last_name}
                type="text"
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} md="6" className="mb-3" controlId="country">
              <Form.Label>{t("common.country")}</Form.Label>
              <Select
                options={countries}
                value={country}
                onChange={onCountryChange}
                defaultInputValue={clientInfo?.shipping_address?.country_code}
              />
            </Form.Group>
            <Form.Group as={Col} md="6" className="mb-3" controlId="phone">
              <Form.Label>{t("dashboardScreen.phoneNumber")}</Form.Label>
              <Form.Control
                defaultValue={clientInfo?.shipping_address?.phone}
                type="text"
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group
              as={Col}
              md="6"
              className="mb-3"
              controlId="streetAddress"
            >
              <Form.Label>{t("dashboardScreen.streetAddress")}</Form.Label>
              <Form.Control
                defaultValue={clientInfo?.shipping_address?.street_address}
                type="text"
                maxLength={30}
              />
            </Form.Group>
            <Form.Group as={Col} md="6" className="mb-3" controlId="apartment">
              <Form.Label>{t("dashboardScreen.aptOptional")}</Form.Label>
              <Form.Control
                defaultValue={clientInfo?.shipping_address?.apartment}
                type="text"
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} md="6" className="mb-3" controlId="city">
              <Form.Label>{t("common.city")}</Form.Label>
              <Form.Control
                defaultValue={clientInfo?.shipping_address?.city}
                type="text"
              />
            </Form.Group>
            <Form.Group as={Col} md="3" className="mb-3" controlId="state">
              <Form.Label>{t("common.state")}</Form.Label>
              <Form.Control
                defaultValue={clientInfo?.shipping_address?.state_code}
                type="text"
                placeholder="e.g. NY"
                maxLength={2}
              />
            </Form.Group>
            <Form.Group as={Col} md="3" className="mb-3" controlId="zipCode">
              <Form.Label>{t("dashboardScreen.zipCode")}</Form.Label>
              <Form.Control
                defaultValue={clientInfo?.shipping_address?.zip_code}
                type="text"
              />
            </Form.Group>
          </Row>
          <Button variant="dark" className="button-update" type="submit">
            {t("common.save")}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ClientInfoModal;
