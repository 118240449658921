import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Nav from "react-bootstrap/Nav";
import { auth, updateUser } from "../firebase/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useTranslation } from "react-i18next";
import LOGO from "../assets/memowrite_logo.svg";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const locales = {
  en: { title: "🇺🇸 English" },
  es: { title: "🇪🇦 Español" },
};

function Navigation() {
  const { t, i18n } = useTranslation();
  const [user] = useAuthState(auth);
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const lang = queryParams.get("lang");

    if (lang === "es" && lang !== user?.language) {
      i18n.changeLanguage(lang);
    }
  }, [location.search]);

  const admins = [
    "aurimas.paliukaitis@kilo.health",
    "support@getmemowrite.com",
    "karol.majevski@kilo.health",
    "andrii.bondarenko@kilo.health",
  ];
  const isAdmin = admins.includes(user?.email);

  const handleChangeLanguage = async (language) => {
    i18n.changeLanguage(language);
    await updateUser({
      uid: user?.uid,
      data: { language },
    });
  };

  return (
    <Navbar collapseOnSelect expand="lg">
      <Container>
        <Navbar.Brand href="/">
          <img src={LOGO} alt="memowrite logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        {user ? (
          <Navbar.Collapse
            id="responsive-navbar-nav"
            className="justify-content-between"
          >
            <Nav>
              <Nav.Link className="mt-2 pr-4 pl-4" href="/">
                {t("common.dashboard")}
              </Nav.Link>
              <Nav.Link className="mt-2 pr-4 pl-4" href="/profile">
                {t("common.profile")}
              </Nav.Link>
              {isAdmin && (
                <Nav.Link className="mt-2 pr-4 pl-4" href="/admin">
                  {t("common.admin")}
                </Nav.Link>
              )}
            </Nav>
            <NavDropdown
              title={locales[i18n?.resolvedLanguage]?.title}
              id="basic-nav-dropdown"
              className="mt-2"
            >
              {Object.keys(locales).map((locale) => (
                <NavDropdown.Item onClick={() => handleChangeLanguage(locale)}>
                  {locales[locale]?.title}
                </NavDropdown.Item>
              ))}
            </NavDropdown>
          </Navbar.Collapse>
        ) : (
          <NavDropdown
            title={locales[i18n?.resolvedLanguage || "en"]?.title}
            id="basic-nav-dropdown"
            className="mt-2"
          >
            {Object.keys(locales).map((locale) => (
              <NavDropdown.Item onClick={() => handleChangeLanguage(locale)}>
                {locales[locale]?.title}
              </NavDropdown.Item>
            ))}
          </NavDropdown>
        )}
      </Container>
    </Navbar>
  );
}

export default Navigation;
