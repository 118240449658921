import { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { doc, updateDoc } from "firebase/firestore";
import { firestore } from "../firebase/firebase";
import { useTranslation } from "react-i18next";

const EditAnswer = ({ data, onHide }) => {
  const [inputValue, setInputValue] = useState("");

  const { t } = useTranslation();

  useEffect(() => {
    if (data?.["stripped-text"]) {
      setInputValue(data?.["stripped-text"]);
    }
  }, [data?.["stripped-text"]]);

  const handleInputChange = (e) => {
    const { value } = e.target;
    setInputValue(value);
  };

  const handleSaveEdit = async () => {
    try {
      const answerRef = doc(firestore, "email-replies", data?.id);
      await updateDoc(answerRef, {
        "stripped-text": inputValue,
      });
    } catch (error) {
      console.error("Error updating document: ", error);
    } finally {
      onHide();
    }
  };

  return (
    <Form>
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Control
          placeholder={t("dashboardScreen.yourAnswer")}
          as="textarea"
          rows={12}
          value={inputValue}
          name="editAnswer"
          onChange={handleInputChange}
        />
      </Form.Group>
      <p style={{ fontSize: 12 }}>
        {t("dashboardScreen.characterCount")}{" "}
        <span style={{ color: inputValue?.length > 4500 ? "red" : "black" }}>
          {inputValue?.length}
        </span>{" "}
        / 4500
      </p>
      <Button
        className="button-update"
        style={{
          backgroundColor: "#60A680",
          borderColor: "#60A680",
          marginRight: 8,
        }}
        onClick={handleSaveEdit}
      >
        {t("common.save")}
      </Button>
      <Button variant="outline-dark" className="button-update" onClick={onHide}>
        {t("common.cancel")}
      </Button>
    </Form>
  );
};

export default EditAnswer;
