import { useState, useRef } from "react";
import { useDownloadURL } from "react-firebase-hooks/storage";
import { ref as storageRef } from "firebase/storage";
import { storage } from "../firebase/firebase";
import { useEffect } from "react";
import EXIF from "exif-js";
import { FaArrowsRotate } from "react-icons/fa6";

const ImageFromStorage = ({
  link,
  height,
  width,
  fullWidth,
  objectFit,
  style,
  editable,
  isAdmin,
  ...rest
}) => {
  const [heightForFullWidth, setHeightForFullWidth] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [externalOrientation, setExternalOrientation] = useState(null);

  const [downloadUrl, loading, error] = useDownloadURL(
    storageRef(storage, link)
  );
  const imgRef = useRef(null);
  const canvasRef = useRef(null);

  function adjustImageOrientation(img, orientation, callback) {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    // Adjust canvas size and orientation based on the EXIF orientation
    if ([5, 6, 7, 8].indexOf(orientation) > -1) {
      canvas.width = img.height;
      canvas.height = img.width;
    } else {
      canvas.width = img.width;
      canvas.height = img.height;
    }

    // Apply the correct transformation based on the orientation
    switch (orientation) {
      case 2:
        ctx.transform(-1, 0, 0, 1, canvas.width, 0);
        break;
      case 3:
        ctx.transform(-1, 0, 0, -1, canvas.width, canvas.height);
        break;
      case 4:
        ctx.transform(1, 0, 0, -1, 0, canvas.height);
        break;
      case 5:
        ctx.transform(0, 1, 1, 0, 0, 0);
        break;
      case 6:
        ctx.transform(0, 1, -1, 0, canvas.height, 0);
        break;
      case 7:
        ctx.transform(0, -1, -1, 0, canvas.height, canvas.width);
        break;
      case 8:
        ctx.transform(0, -1, 1, 0, 0, canvas.width);
        break;
      default:
        ctx.transform(1, 0, 0, 1, 0, 0); // No transformation needed
    }

    ctx.drawImage(img, 0, 0);
    callback(canvas.toDataURL("image/jpeg"));
  }

  useEffect(() => {
    if (downloadUrl && imgRef?.current && isAdmin) {
      fetch(downloadUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const fileReader = new FileReader();
          fileReader.onload = function (e) {
            const img = new Image();
            img.onload = () => {
              EXIF.getData(img, function () {
                const orientation = EXIF.getTag(this, "Orientation");
                adjustImageOrientation(
                  img,
                  externalOrientation || orientation,
                  (dataUrl) => {
                    setImageSrc(dataUrl);
                  }
                );
              });
            };
            img.crossOrigin = "Anonymous";
            img.src = e.target.result;
          };
          fileReader.readAsDataURL(blob);
        })
        .catch((error) =>
          console.error("Error fetching proxied image:", error)
        );
    }
  }, [downloadUrl, imgRef?.current, externalOrientation, isAdmin]);

  useEffect(() => {
    if (fullWidth && imgRef?.current?.height) {
      setHeightForFullWidth(imgRef?.current?.height);
    }
  }, [fullWidth, imgRef?.current?.height]);

  const handleChangeOrientation = () => {
    const orientation = externalOrientation || 1;

    if (orientation === 8) {
      setExternalOrientation(1);
    } else {
      setExternalOrientation(orientation + 1);
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <canvas ref={canvasRef} style={{ display: "none" }}></canvas>
      <img
        ref={imgRef}
        src={imageSrc || downloadUrl}
        style={{
          objectFit: objectFit || "contain",
          height: fullWidth ? heightForFullWidth : height,
          width: fullWidth ? "100%" : width,
          ...style,
        }}
        {...rest}
      />
      {editable && (
        <div
          onClick={handleChangeOrientation}
          style={{
            position: "absolute",
            top: 32,
            right: 32,
            cursor: "pointer",
          }}
        >
          <FaArrowsRotate size={24} />
        </div>
      )}
    </div>
  );
};

export default ImageFromStorage;
