import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import AnswerItem from "./AnswerItem";
import { useTranslation } from "react-i18next";

const AllAnswers = ({ isLoading, answers }) => {
  const { t } = useTranslation();

  const availableQuestions = answers?.filter((item) => item?.available);
  const lastAvailableQuestion =
    availableQuestions?.[availableQuestions?.length - 1];
  const islastQuestionCurrent = (answer) =>
    lastAvailableQuestion?.questionId === answer?.questionId &&
    lastAvailableQuestion?.answers?.length === 0;

  return (
    <Col>
      <h2 style={{ fontSize: 22, fontWeight: 500, marginBottom: 40 }}>
        {t("dashboardScreen.yourLifeQuestions")}
      </h2>
      {isLoading ? (
        <Col className="d-flex justify-content-center align-items-center">
          <Spinner animation="border" />
        </Col>
      ) : (
        <div className="all-answers-container">
          {answers &&
            answers?.map((answer) => {
              return (
                <AnswerItem
                  key={`answer_${answer?.questionId}`}
                  data={answer}
                  current={islastQuestionCurrent(answer)}
                />
              );
            })}
        </div>
      )}
    </Col>
  );
};

export default AllAnswers;
