import { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import QuestionTemplate from "./QuestionTemplate";
import { Button } from "react-bootstrap";
import InfoModal from "./InfoModal";
import { formatNumberDeci } from "../utils/helpers";
import { useTranslation } from "react-i18next";

const NextQuestion = ({ lastAvailableQuestion, nextQuestion }) => {
  const [seconds, setSeconds] = useState(0);
  const [start, setStart] = useState(false);
  const [whyDelayedModal, setWhyDelayedModal] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    const lastTimestamp = lastAvailableQuestion?.answers?.sort(
      (a, b) => a?.timestamp - b?.timestamp
    )?.[0]?.timestamp;

    setSeconds(lastTimestamp + 4 * 60 * 60 - Math.round(Date.now() / 1000));
    setStart(true);
  }, [lastAvailableQuestion?.answers?.length]);

  useEffect(() => {
    let interval;

    if (start) {
      interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [start]);

  return (
    <Row>
      <Col
        className="next-question-timer-container"
        xs="12"
        md="6"
        style={{
          textAlign: "center",
          alignItems: "flex-start",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <h6 className="next-question">
          {t("dashboardScreen.nextQuestionUnlocksIn")}
        </h6>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
            fontFamily: "Sentient",
            color: "#60A680",
            marginBottom: 32,
          }}
        >
          <span style={{ fontSize: 50, fontWeight: 500 }}>
            {Math.floor(seconds / 3600)}
          </span>
          <span style={{ fontSize: 32, fontWeight: 400, marginBottom: 8 }}>
            hrs&nbsp;
          </span>
          <span style={{ fontSize: 50, fontWeight: 500 }}>
            {formatNumberDeci(Math.floor((seconds % 3600) / 60))}
          </span>
          <span style={{ fontSize: 32, fontWeight: 400, marginBottom: 8 }}>
            min&nbsp;
          </span>
          <span style={{ fontSize: 50, fontWeight: 500 }}>
            {formatNumberDeci(seconds % 60)}
          </span>
          <span style={{ fontSize: 32, fontWeight: 400, marginBottom: 8 }}>
            s
          </span>
        </div>
        <Button
          onClick={() => setWhyDelayedModal(true)}
          variant="outline-dark"
          style={{
            fontWeight: 500,
            borderRadius: 20,
            paddingTop: 8,
            paddingBottom: 8,
            paddingLeft: 16,
            paddingRight: 16,
          }}
        >
          {t("dashboardScreen.whyQuestionDelayed")}
        </Button>
      </Col>
      <Col xs="12" md="6">
        <QuestionTemplate
          id={nextQuestion?.questionId}
          question={nextQuestion?.question}
          context={nextQuestion?.context}
          disabled={true}
        />
      </Col>
      <InfoModal
        visible={whyDelayedModal}
        onHide={() => setWhyDelayedModal(false)}
        title={t("dashboardScreen.whyQuestionDelayed")}
        text={t("dashboardScreen.spacingOutQuestions")}
      />
    </Row>
  );
};

export default NextQuestion;
