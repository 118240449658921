import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { ref, uploadBytes } from "firebase/storage";
import { storage, firestore, auth, updateUser } from "../firebase/firebase";
import { doc } from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { useDocument } from "react-firebase-hooks/firestore";
import { useTranslation } from "react-i18next";

const AddPhotoModal = ({ visible, onHide, uid }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageDescription, setImageDescription] = useState(null);

  const { t } = useTranslation();
  const [user] = useAuthState(auth);
  const userId = uid || user?.uid;

  const [userDataRaw] = useDocument(doc(firestore, "users", userId), {
    snapshotListenOptions: { includeMetadataChanges: true },
  });
  const userData = userDataRaw?.data();
  const questionId = visible;

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  const handleImageDescription = (e) => {
    setImageDescription(e.target.value);
  };

  const generateUniqueId = () => {
    const timestamp = Date.now(); // Current timestamp in milliseconds
    const randomNum = Math.floor(Math.random() * 1000); // Random number between 0-999
    return (timestamp + randomNum).toString(36); // Convert the number to a base-36 string
  };

  const handleSave = async () => {
    try {
      // Upload photo
      const photoPath = `${Date.now()}_${userData?.email?.replace(
        /[^a-zA-Z0-9]+/g,
        "_"
      )}-${questionId}/${generateUniqueId()}_${selectedFile?.name}`;
      const photoRef = ref(storage, photoPath);
      const uploadedFile = await uploadBytes(photoRef, selectedFile);

      if (!userData) {
        return;
      }

      // Update user
      await updateUser({
        uid: userId,
        data: {
          book_qa_photos: {
            ...(userData?.book_qa_photos || {}),
            [questionId]: [
              ...(userData?.book_qa_photos?.[questionId] || []),
              {
                path: uploadedFile?.metadata?.fullPath,
                image_description: imageDescription,
              },
            ],
          },
        },
      });
    } catch (error) {
      console.error("Error adding photo: ", error);
    } finally {
      setImageDescription(null);
      onHide();
    }
  };

  return (
    <Modal
      show={visible}
      onHide={onHide}
      size="m"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton style={{ border: "none" }} onHide={onHide}>
        <p style={{ fontSize: 20, fontWeight: 700, marginBottom: 0 }}>
          {t("dashboardScreen.questionPhoto")} #{questionId}
        </p>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>{t("dashboardScreen.selectPhoto")}</Form.Label>
            <Form.Control
              type="file"
              accept=".png, .jpg, .jpeg"
              onChange={handleFileChange}
            />
            <p style={{ fontSize: 12, color: "grey", marginTop: 12 }}>
              {t("dashboardScreen.photoFileFormats")}
            </p>
          </Form.Group>
          <Form.Group className="mb-3" controlId="imageDescription">
            <Form.Label>{t("dashboardScreen.imageDescription")}</Form.Label>
            <Form.Control
              value={imageDescription}
              onChange={handleImageDescription}
              type="text"
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer style={{ border: "none" }}>
        <Button
          style={{ backgroundColor: "#60A680", borderColor: "#60A680" }}
          className="button-update"
          onClick={handleSave}
        >
          {t("common.save")}
        </Button>
        <Button
          variant="outline-dark"
          className="button-update"
          onClick={onHide}
        >
          {t("common.cancel")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddPhotoModal;
