import { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { collection, addDoc } from "firebase/firestore";
import { firestore } from "../firebase/firebase";
import { auth } from "../firebase/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import AddPhotos from "./AddPhotos";
import { useTranslation } from "react-i18next";

const AddAnswer = ({ data }) => {
  const [inputValue, setInputValue] = useState("");

  const [user] = useAuthState(auth);
  const { t } = useTranslation();

  const handleInputChange = (e) => {
    const { value } = e.target;
    setInputValue(value);
  };

  const handleSaveAdd = async () => {
    try {
      const answerRef = collection(firestore, "email-replies");
      const date = new Date();
      const dateString = date.toString();

      await addDoc(answerRef, {
        date: dateString,
        timestamp: Math.round(Date.now() / 1000),
        questionId: data?.questionId,
        sender: user?.email,
        "stripped-text": inputValue,
      });
    } catch (error) {
      console.error("Error updating document: ", error);
    } finally {
      setInputValue("");
    }
  };

  return (
    <Form>
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Control
          as="textarea"
          rows={12}
          value={inputValue}
          name="editAnswer"
          onChange={handleInputChange}
        />
      </Form.Group>
      <p style={{ fontSize: 12 }}>
        {t("dashboardScreen.characterCount")}{" "}
        <span style={{ color: inputValue?.length > 4500 ? "red" : "black" }}>
          {inputValue?.length}
        </span>{" "}
        / 4500
      </p>
      <Button
        className="button-update"
        style={{
          backgroundColor: "#60A680",
          borderColor: "#60A680",
        }}
        onClick={handleSaveAdd}
      >
        {t("common.save")}
      </Button>
    </Form>
  );
};

export default AddAnswer;
