import { isBefore, addHours, addSeconds } from "date-fns";
import i18n from "i18next";

const getUserAnswers = ({ rawAnswers, userEmail }) =>
  rawAnswers?.docs
    ?.map((doc) => {
      const data = doc.data();
      const questionId = Number(data?.questionId);
      const id = doc?.id;
      const photos = data?.attachments?.map(
        (attachmentData) => attachmentData?.name
      );

      return {
        ...data,
        questionId,
        photos,
        id,
      };
    })
    ?.filter((item) => {
      return item?.sender === userEmail;
    });

const getUserQuestions = ({ rawQuestions, userAnswers, bookQaPhotos }) =>
  rawQuestions?.docs
    ?.map((q) => {
      const questionId = q?.id;
      const qAnswers = userAnswers?.filter(
        (a) => Number(a?.questionId) === Number(questionId)
      );
      const qPhotos = qAnswers
        ?.filter((a) => a?.photos)
        ?.map((a) => a?.photos)
        ?.flat()
        ?.map((p) => ({ path: p, image_description: null }));

      const newPhotos = bookQaPhotos?.[questionId]?.map((item) => item);

      return {
        context: i18n.t(`question_${questionId}.context`),
        question: i18n.t(`question_${questionId}.question`),
        questionAsTitle: i18n.t(`question_${questionId}.questionAsTitle`),
        questionId,
        answers: qAnswers,
        photos: [...(qPhotos || []), ...(newPhotos || [])],
      };
    })
    ?.sort((a, b) => {
      return a?.questionId - b?.questionId;
    });

export const getStructuredUserQuestionsWithAnswers = ({
  questions,
  answers,
  userEmail,
  bookQaPhotos,
}) => {
  const userAnswers = getUserAnswers({ rawAnswers: answers, userEmail });
  const userQuestions = getUserQuestions({
    rawQuestions: questions,
    userAnswers,
    bookQaPhotos,
  });
  const modifiedUserQuestions = userQuestions?.map(
    (currentItem, index, array) => {
      if (index <= array.length - 1) {
        return {
          ...currentItem,
          previousAnswers: array[index - 1]?.answers || [],
        };
      } else {
        return currentItem;
      }
    }
  );

  const addTime = (a) =>
    userEmail === "susan.johnson@kilo.health"
      ? addSeconds(a?.timestamp * 1000, 1)
      : addHours(a?.timestamp * 1000, 4);

  const modifiedUsersQuestionsWithAvailability = modifiedUserQuestions?.map(
    (q, idx) => {
      const checkAvailability = () => {
        if (idx === 0) {
          return true;
        }
        if (
          q?.previousAnswers?.filter((a) => isBefore(addTime(a), Date.now()))
            ?.length > 0
        ) {
          return true;
        }

        return false;
      };

      return {
        ...q,
        available: checkAvailability(),
      };
    }
  );

  return modifiedUsersQuestionsWithAvailability;
};
